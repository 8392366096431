<template>
  <v-container class="general">
    <PageTitle :component="'GroupStripeConnectedAccount'" />
    <AccountOnboarding
      v-if="!stripeConnectedAccount.details_submitted"
      @account-onboarding-exit="checkAccountStatus()"
    />
    <NotificationBanner v-if="stripeConnectedAccount.details_submitted" />
    <AccountManagement
      v-if="
        stripeConnectedAccount.details_submitted &&
        stripeConnectedAccount.charges_enabled
      "
    />
  </v-container>
</template>

<script>
import AccountOnboarding from "@/components/Stripe/AccountOnboarding";
import AccountManagement from "@/components/Stripe/AccountManagement";
import NotificationBanner from "@/components/Stripe/NotificationBanner";

export default {
  data: () => ({
    stripeConnectedAccount: {},
  }),
  components: {
    AccountOnboarding,
    AccountManagement,
    NotificationBanner,
  },
  computed: {
    activeGroup() {
      return this.$store.getters.activeGroup || {};
    },
    groupId() {
      return this.activeGroup.id;
    },
  },
  watch: {
    groupId: {
      handler() {
        this.checkAccountStatus();
      },
    },
  },
  mounted() {
    this.checkAccountStatus();
  },
  methods: {
    async checkAccountStatus() {
      if (!this.groupId) {
        return null;
      }

      try {
        const response = await this.$api.groupPayments.fetch_client_account(
          this.groupId,
        );

        // details_submitted: true
        // charges_enabled: true
        // transfers_enabled: true
        this.stripeConnectedAccount = response.data.data;
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>

<style scoped></style>
